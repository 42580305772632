// Entry point for the build script in your package.json
import './jquery-loader.js'
import 'bootstrap'; // only carousel, collapse, dropdowns and modals are needed -- maybe less

//// we do not use yandex any more
////  ga is embedded via Google Tag manager
////  whole piece of code was supposedly required only because of turbolinks
// require("./user/trackers.js").init();


import page_updater_init from './page-updater.js';
import article_init from './article.js';
import banners_tracker_init from './banners.js';
//import datepickers_init from './user/datepicker.js';

$(document).ready( () => {
    page_updater_init();
    article_init();
    banners_tracker_init();
    //datepickers_init(); 
    return true;
  }
);
