import $ from 'jquery';
import { Application } from '@hotwired/stimulus'
//import ContentLoader from 'stimulus-content-loader'
import CustomContentLoader from './custom-content-loader'

class ContentLoaderPagetop extends CustomContentLoader {
  
  loadScripts() { 
            this.element.querySelectorAll("script").forEach(t=>{
                const script = document.createElement("script");
                script.innerHTML = t.innerHTML;
                console.log("loader script is ", script);
                document.head.appendChild(script).parentNode.removeChild(script);
               }
            )
        }

}

function admin_details_fill_page_version() {
  let $timeslot = $('.admin-details-widget dl.page_time time');
  let generated_fmt = $('body').attr('data-generated-fmt') ;
  let generated_ts  = $('body').attr('data-generated');

  $timeslot.text( generated_fmt );

  try {
    let page_generation_time = new Date( generated_ts );  
    let now = new Date();
    let page_age = ( now - page_generation_time ) / 1000 ;
    if (page_age > 60) {
      $timeslot.addClass('bg-danger text-white p-1');
    } else if (page_age > 3) {
      $timeslot.addClass('bg-warning p-1');
    } else {
      $timeslot.removeClass();
    }
  } catch { 
    // do nothing ; 
  }

}

function admin_details_fill_card($card, config) {
  let $card_canvas = $card.find('.canvas');
  let $card_header = $card.find('.header > p');
  let $card_desc   = $card.find('.desc > p');

  if (config.img) {
    let img = document.createElement('img');
    $(img).attr('src', config.img);
    $card_canvas.append(img);
  } else { 
    $card_canvas.hide();
  }
  $card_header.text( config.title );
  $card_desc.text( config.description );
}

function admin_details_fill_seo() {
  let $seo_card = $('.admin-details-widget .card.seo');
  let seo_img   = $('link[rel="image_src"]').attr('href');
  let seo_title = $('title').text();
  let seo_desc  = $("meta[name='description']").attr('content');

  admin_details_fill_card( $seo_card, { img: seo_img,
                                        title: seo_title,
                                        description: seo_desc });
}

function admin_details_fill_smm() { 
  let $smm_card = $('.admin-details-widget .card.smm');
  let smm_img    = $("meta[property='og:image']").attr('content');
  let smm_title  = $("meta[property='og:title']").attr('content');
  let smm_desc   = $("meta[property='og:description']").attr('content');
  admin_details_fill_card( $smm_card, { img: smm_img,
                                        title: smm_title,
                                        description: smm_desc });
}

function admin_toggle_editor_warning(is_on) {
  console.log("ADMIN: applying warning status", is_on);
  let $toggler = $('.btn-toggler[data-bs-toggle="offcanvas"][data-bs-target="#admin-details-widget"]');
  if (is_on) {
    $toggler.addClass('bg-warning');
  } else {
    $toggler.removeClass('bg-warning');
  }
}

function on_admin_article_status_update(e) {
  if (e.detail) {
    console.log("ADMIN: updating article warning to", e.detail.warning);
    admin_toggle_editor_warning(e.detail.warning);
  }
}

function admin_set_initial_warning() {
  let warning = $('[data-editor-warning="1"]').length;
  console.log("ADMIN: setting initial warning to", warning);
  admin_toggle_editor_warning(warning);
}

function on_admin_details_load() {
  $('header.site-header').addClass('admin');
  admin_details_fill_page_version();
  admin_details_fill_seo();
  admin_details_fill_smm();
  admin_set_initial_warning();
}

function on_confirmable_click(ev) {
  let confirmation_text = $(ev.target).attr('data-confirm');
  if (confirmation_text) {
    let r = confirm(confirmation_text);
    if (r) {
      return true ;
    } else {
      ev.stopImmediatePropagation();
      ev.preventDefault();
    }
    //return confirm(confirmation_text);
    /*if(!confirm(confirmation_text) ) {
    }*/
  }
  return true;
}

export default function page_updater_init() {
  $(document).on('novychas:admin:details:loaded', on_admin_details_load);
  $(document).on('novychas:admin:article_status:updated', on_admin_article_status_update);
  $('body').on('click', "button[data-confirm!='']", on_confirmable_click);

  try {
    window.stimulus||= Application.start()
    window.stimulus.register('content-loader', CustomContentLoader)
  } catch(err) {
    console.log("ERROR: failed to init page updater", err);
  }
}
