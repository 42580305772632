import ContentLoader from '@stimulus-components/content-loader';

export default class CustomContentLoader extends ContentLoader {
  fetch () {
    let self=this;
    fetch(this.urlValue)
      .then( (x) => self.on_fetch(x) )
      .then( (x) => self.postprocess(x) )
      .then( (x) => self.replace_html(x) )
      .catch(error => {
        console.log("catched error:", error);
        this.dispatch('error', { detail: { error } })
      })
  }

  on_fetch(response) {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.text() ;
  }

  postprocess(html) {
    return html;
  }

  replace_html(html) {
    this.element.innerHTML = html

    if (this.loadScriptsValue) {
      this.loadScripts()
    }

    this.dispatch('success')
  }
}
  
