import Lightbox from 'bs5-lightbox';
import { track_image_opened, track_gallery_interaction, track_article_interaction } from './events_tracker.js';
import $ from 'jquery'; 

import { Application }  from '@hotwired/stimulus';
import { IntersectionController } from 'stimulus-use' ;

const LIGHTBOX_SELECTOR = '[data-toggle="lightbox-custom"]'

// constrain: false would supposedly remove backdrop which is bad visually
const LIGHTBOX_CONFIG   = {size: 'fullscreen', 
                           constrain: false,
                           target: LIGHTBOX_SELECTOR}

// we use custom opener as Lightbox.initializer has a bug 
// which does not stop click propagaton and leads to creation of a duplicate modal
function lightbox_opener(e){
  e.preventDefault();
  e.stopPropagation();
  //console.log("opening lightbox with params -> ", LIGHTBOX_CONFIG);
  //console.log("LIGHTBOX: initializing for ", e.currentTarget);
  const lightbox = new Lightbox(e.currentTarget, LIGHTBOX_CONFIG);
  //console.log("LIGHTBOX: ligthbox ", lightbox.src);
  lightbox.show();

  let initiator = e.currentTarget ;
  let target_url = initiator && initiator.getAttribute('data-src');
  if ( $(initiator).parents('figure.novychas.gallery').length ) {
    track_gallery_interaction( target_url );
  } else {
    if(target_url) { 
      track_image_opened( target_url ); 
    }
  }

  return false;
}

function track_gallery_swipe(e) {
  let target_url = $(e.currentTarget).closest('.novychas.gallery').find('.carousel-item.active > picture').attr('data-src');
  track_gallery_interaction(target_url);
  return true;
}

function track_social_outlet_visit(e) {
  //console.log("Tracking SOCIAL OUTLET interaction",$(e.currentTarget));
  let platform = $(e.currentTarget).attr('data-platform');
  if (platform) {
    track_article_interaction("social", platform);  
  }
  return true;
}

function click_tracker(interaction_type) {
  return function(e) {
    track_article_interaction(interaction_type, e.target.getAttribute('href'));
    return true;
  }
}

function on_btn_share_click(e) {
  let $btn = $(e.currentTarget);
  let platform = $btn.attr('data-platform');
  let target   = $btn.attr('data-target');
  if (target) { 
    window.open(target, "_blank");
    if (platform) {
      track_article_interaction("share", platform, target);
    }
  }
  e.stopPropagation();
  e.preventDefault();
  return false;
}
  
class ArticleBottomTracker extends IntersectionController {
  options = { threshold: 1.0 }
  appear(entry, observer) {
    if (! this.bottom_intersection_tracked ) {
      track_article_interaction("content_consumed","");
      this.bottom_intersection_tracked = true;
      //observer.unobserve();
    }
  }
}

class BannerViewTracker extends IntersectionController {
  options = { threshold: 1.0 }
  appear(entry, observer) {
    let $target = $( entry.target ) ;
    //console.log("TRACKER->entry.target", entry.target);
    if (! $target.data('banner_intersection_tracked' ) ) {
      $target.trigger('load');
      $target.data('banner_intersection_tracked',true);
    }
  }
}


function article_trackers_init() {
  // in fact this setup hook is run on all pages, but most selectors are article-specific

  $('.novychas.gallery').on('click swipe', track_gallery_swipe);
  $('.btn-share').on('click', on_btn_share_click);
  $('.publication-body').on('click', 'a.article-link', click_tracker('related_in-article'));
  $('.publication-body').on('click', 'a.external', click_tracker('link_click'));
  $('aside.related').on('click','a', click_tracker('related_article'));
  $('.cta.default').on('click', 'a', track_social_outlet_visit);
 
  if ($('article.novychas-publication .publication-body').length) {
    $('.novychas-social-links').on('click','a',track_social_outlet_visit);
  }
 
  try {
    window.stimulus||= Application.start()
    window.stimulus.register('article-bottom-tracker', ArticleBottomTracker)
    window.stimulus.register('banner-view-tracker', BannerViewTracker)
  } catch(err) {
    console.log("ERROR: failed to init page updater", err);
  }
}

function lightbox_init() { 
  for (const el of document.querySelectorAll(LIGHTBOX_SELECTOR) ){
    el.addEventListener('click', lightbox_opener);
  } 
}


export default function article_init() {
  lightbox_init();
  article_trackers_init();
}
